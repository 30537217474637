export default function nav() {
    const firstLevel = Array.from(document.querySelectorAll('.first-level'))
    //const openNavbtn = document.getElementById('openbtn')
    //const closeNavbtn = document.getElementById('closebtn')

    firstLevel.forEach((item) => {

        item.addEventListener('mouseover', (event) => {
          item.classList.add('show')
        })

        item.addEventListener('mouseleave', (event) => {
            setTimeout(function () {
                item.classList.remove('show')
            }, 200)
        })

    })

    // mobile nav
    let levelThree = Array.from(document.querySelectorAll('.level-three'))
    let mobileNav  = document.getElementById("trigger")

    mobileNav.addEventListener('click', (event) => {
        mobileNav.classList.toggle('open')
        console.log('trigger clicked!')
    })

    levelThree.forEach((navList) => {
        navList.addEventListener('click', (event) => {
            navList.classList.toggle('show')
        })
    })

}