export default function textSlider() {

    const headlinesPhysical = Array.from(document.querySelectorAll('.physical .teaser-headline'))
    const headlinesDigital  = Array.from(document.querySelectorAll('.digital .teaser-headline'))

    const teasersPhysical   = Array.from(document.querySelectorAll('.physical .teaser'))
    const teasersDigital    = Array.from(document.querySelectorAll('.digital .teaser'))

    function selectTeasers(headlines, teasers) {
        headlines.forEach((headline) => {
            headline.addEventListener('click', (event) => {

                headlines.forEach((headline) => {
                    headline.classList.remove('active')
                })

                if (!headline.classList.contains('active')) {
                    headline.classList.add('active')
                }

                teasers.forEach((element) => {
                    element.classList.remove('active')
                })

                document.getElementById(headline.dataset.teaser).classList.add('active')

            })
        })
    }

    selectTeasers(headlinesPhysical,teasersPhysical)
    selectTeasers(headlinesDigital,teasersDigital)
}