import smoothscroll from 'smoothscroll-polyfill';

window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

import footerAccordion from "./partials/footer-accordion"
import heroSwiper from "./partials/swiper"
import textSlider from "./partials/text-slider"
import tabsAccordion from "./partials/tabsAccordion";
import mlMenu from "./partials/ml-menu";
import nav from "./partials/nav";
import slideInMenu from "./plugins/mlpushmenu";
import cassieJsPlugin from "./plugins/classie";
import swipeAccordion from "./partials/swipeAccordion";
import contentTabs from "./partials/content-tabs";

// initialize functions
cassieJsPlugin()
slideInMenu()
footerAccordion()
heroSwiper()
textSlider()
tabsAccordion()
nav()
mlMenu()
swipeAccordion()
contentTabs()
