export default function contentTabs() {
    let tabs = Array.from(document.querySelectorAll('.radio'))
    let onePanel = document.getElementById('one-panel-anchor')
    let mediaQuery = window.matchMedia('(max-width: 768px)')

    tabs.forEach((item) => {
        item.addEventListener('click', (event) => {

            if (mediaQuery.matches) {
                onePanel.scrollIntoView({behavior: 'smooth', block: 'start'})
            }

        })
    })

}