export default function swipeAccordion() {
    const swipeAccordion = Array.from(document.querySelectorAll('.swiper-accordion .swipe-accordion'))

    swipeAccordion.forEach((item) => {
        item.addEventListener('click', (event) => {
            let accSwipeAccordion = document.getElementById('accordion-swipe-slider')

            if (item.classList.contains('active')) {
                item.classList.remove('active')
            } else {
                swipeAccordion.forEach((content) => {
                    content.classList.remove('active')
                })

                item.classList.add('active')
                accSwipeAccordion.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }

        })

    })
}