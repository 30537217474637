export default function tabsAccordion() {

    const tabsAccordion = Array.from(document.querySelectorAll('.tabs-accordion .tab-accordion'))

    tabsAccordion.forEach((item) => {
        item.addEventListener('click', (event) => {
            let mobileAccordion = document.getElementById('mobile-accordion')

            if (item.classList.contains('active')) {
                item.classList.remove('active')
            } else {
                tabsAccordion.forEach((content) => {
                    content.classList.remove('active')
                })

                item.classList.add('active')
                mobileAccordion.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }

        })

    })

}